import Name from './Name.js';
import React from 'react';
import Draw from './Shuffle'

class OpenDraw extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            names: [],
            pairings: [],
        };
    }

    onBlur(i, name) {
        const names = this.state.names.slice();
        names[i] = name;
        this.setState({
            names: names
        });
    }

    addName() {
        var names = this.state.names.slice();
        this.setState({
            names: names.concat("")
        });
    }

    removeName() {
        var names = this.state.names.slice(0, this.state.names.length-1);
        this.setState({
            names: names
        });
    }

    performDraw() {
        let pairs = Draw(this.state.names.slice());
        this.setState({
            pairings: pairs
        })
    }

    render() {
        const names = this.state.names;
        const pairings = this.state.pairings;
        const rows = names.map((name, index) => {
            const pairing = names[pairings[index]];
            return (
               <Name key={index} 
                     pairing={pairing}
                     name={name}
                     onBlur={this.onBlur.bind(this)}
                     index={index}/>
            )
        });

        return (
            <div>
                <div>{rows}</div>
                <div>     
                    <button onClick={() => this.addName()} >Add Name</button>
                    <button onClick={() => this.removeName()} >Remove</button>
                </div>
                <div>
                    <button onClick={() => this.performDraw()}>Do the Draw</button>
                </div>
            </div>
        );
    }
}

export default OpenDraw;