import './App.css';
import OpenDraw from './OpenDraw.js';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Names From a Hat
        </h1>
        <p>Add your names</p>
        <OpenDraw />
      </header>
    </div>
  );
}

export default App;
