
function idList(size) {
    var list = new Array(size);
    for(var i = 0; i < size; i++) {
        list[i] = i;
    }
    return list;
}

function shuffle(names) {
    let pairings = [];
    if (names.length === 1) {
        pairings[0] = names[0];
        return pairings;
    }

    // To prevent problems with name dupes, swap from using the names as keys and use
    // the numeric index of the name in the list. Then when all of the shuffling is
    // done, rebuild the pairings list from the chosen pairs. 
    let selections = new Array(names.length);
    names.forEach((name, index) => {
        let taken = selections.slice();
        let hat = idList(names.length).filter(x => !taken.includes(x) && index !== x);
        if (hat.length === 0) {
            return pairings;
        }

        let hatIndex = Math.floor(Math.random() * hat.length);
        selections[index] = hat[hatIndex];
    });
    
    return selections;
}

function Draw(names) {
    while (true) {
        const pairings = shuffle(names);
        if (Object.entries(pairings).length === names.length) {
            return pairings;
        }
    }
}

export default Draw;