function renderInitial(props) {

    return (
        <div>
            <input type="text" 
                   className="name" 
                   name={props.name}
                   onBlur={(e) => props.onBlur(props.index, e.target.value)}
                   />
        </div>
    )
}

function renderReveal(props) {
    return (
        <div>
            <input type="text" 
                   className="name" 
                   value={props.name}
                   readonly
                   /> 
            -&gt;
            <input type="text"
                   className="name"
                   readOnly
                   value={props.pairing} />
        </div>
    )
}

function Name(props) {

    if (props.pairing) {
        return renderReveal(props);
    }
    return renderInitial(props);
}

export default Name;